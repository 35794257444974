import React, { useState, useEffect } from "react"
import Imgix from "react-imgix"
import styled from "styled-components"
import { useStoreState } from "easy-peasy"
import { navigate } from "@reach/router"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import { ReactComponent as SingleTopicIcon } from "@assets/images/single-topic-icon.svg"
import { ReactComponent as ExerciseModeIcon } from "@assets/images/exercise-mode-icon.svg"
import { ReactComponent as TestModeIcon } from "@assets/images/test-mode-icon.svg"
import { ReactComponent as ExamModeIcon } from "@assets/images/exam-mode-icon.svg"

import UploadImage from "@assets/images/upload.png"
import MarkImage from "@assets/images/mark.png"
import BannerImage from "@assets/images/banner.png"
import ClassroomImage from "@assets/images/classroom.png"
import SpotlightImage from "@assets/images/spotlight.png"
import BuildingHeadImage from "@assets/images/building-head.png"
import FlyingBookImage from "@assets/images/flying-book-crop.png"
import SittingBookImage from "@assets/images/sitting-book.png"
import StrongShadowManImage from "@assets/images/strong-shadow-man.png"

import Banner1 from "@assets/images/homepage-banner-1.jpg"
import Banner2 from "@assets/images/homepage-banner-2.jpg"

import { Box, Typography, Button, Grid, SvgIcon, Carousel } from "@lib/ui/core"
import AboutMethodSvg from "@assets/images/about-mathod-trans.gif"

const TopSection = styled(Box)`
  background: radial-gradient(circle at 50% -250%, #14a1e9 95%, #74c7f2 95%);
`

const Banner = styled(Box)`
  position: absolute;
  background-image: url(${BannerImage});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 30vw;
`

const SecondSection = styled(Box)`
  background: radial-gradient(circle at 50% -250%, #74c7f2 85%, #fff 85%);
`

const AboutMethodText = styled(Typography)`
  color: red;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(transparent, transparent),
    url(${AboutMethodSvg});
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
`

const IndexPage = () => {
  return (
    <Layout homepage>
      <TopSection>
        <Box display="flex" width="100%">
          <Container>
            <Box pt={{ xs: 5, lg: 10 }}>
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <Box align="center">
                    <Typography variant="h1" color="textSecondary">
                      隨時隨地
                    </Typography>
                    <Typography variant="h1" color="textSecondary">
                      探索你的Mathod
                    </Typography>
                    <Box py={2}>
                      <Typography color="textSecondary">
                        海量試題，由你發揮！ 「適合中一至中六學生」
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate("/product")
                        }}
                      >
                        <Typography variant="body1" color="textSecondary">
                          立即開始
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    display="flex"
                    width="100%"
                    position="relative"
                    justifyContent="flex-end"
                    my={5}
                  >
                    <Banner />
                    <Box width="50%">
                      <Box border={4} borderColor="#fff" borderRadius="10px">
                        <Carousel>
                          <Imgix src={Banner1} width="100%" />
                          <Imgix src={Banner2} width="100%" />
                        </Carousel>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </TopSection>
      <SecondSection id="whatismethod">
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            // pt={10}
            py={{ xs: 5, lg: 10 }}
          >
            <Typography variant="h1">四大核心模式</Typography>
          </Box>
          <Box my={5}>
            <Grid container spacing={{ xs: 1, lg: 2 }} justify="center">
              <Grid item xs={6} lg={3}>
                <Box display="flex" justifyContent="center">
                  <Box width="11rem" align="justify">
                    <Box pt={2} pb={5}>
                      <SvgIcon component={SingleTopicIcon} fontSize="11rem" />
                    </Box>
                    <Box pb={1} color="#0096D5" align="center">
                      <Typography variant="h4">單一課題</Typography>
                    </Box>
                    <Box color="#707070">
                      <Typography variant="body1">
                        針對個別課題，甚至微細至該課題的不同副項亦能精準狙擊。
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Box display="flex" justifyContent="center">
                  <Box width="11rem" align="justify">
                    <Box pt={2} pb={5}>
                      <SvgIcon component={ExerciseModeIcon} fontSize="11rem" />
                    </Box>
                    <Box pb={1} color="#EDE470" align="center">
                      <Typography variant="h4">綜合練習</Typography>
                    </Box>
                    <Box color="#707070">
                      <Typography variant="body1">
                        橫跨課題，縱跨年級，隨心所欲製作你所需要的練習。
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Box display="flex" justifyContent="center">
                  <Box width="11rem" align="justify">
                    <Box pt={2} pb={5}>
                      <SvgIcon component={TestModeIcon} fontSize="11rem" />
                    </Box>
                    <Box pb={1} color="#F26061" align="center">
                      <Typography variant="h4">測驗模式</Typography>
                    </Box>
                    <Box color="#707070">
                      <Typography variant="body1">
                        每個同學都會面對不同範圍的quiz、UT、期中考，此模式可製作合適的模擬測試卷，並設有時限，增加臨場感。
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Box display="flex" justifyContent="center">
                  <Box width="11rem" align="justify">
                    <Box pt={2} pb={5}>
                      <SvgIcon component={ExamModeIcon} fontSize="11rem" />
                    </Box>
                    <Box pb={1} color="#1CC2BF" align="center">
                      <Typography variant="h4">考試模式</Typography>
                    </Box>
                    <Box color="#707070">
                      <Typography variant="body1">
                        分別可製作卷一及卷二，切合不同的考試範圍，並設有固定時限，增加臨場感。
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SecondSection>
      <Container>
        <Box display="flex" justifyContent="center" width="100%" pt={5} pb={3}>
          <Box>
            <Box
              bgcolor="#B70000"
              borderRadius={42}
              display="flex"
              justifyContent="center"
              px={15}
            >
              <Typography color="textSecondary" variant="subtitle1">
                額外改卷服務
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#4D4D4D"
              pt={3}
            >
              <Typography variant="subtitle2">只需</Typography>
              <Typography variant="h3">$49</Typography>
              <Typography variant="subtitle2">每份</Typography>
            </Box>
          </Box>
        </Box>
        <Box mb={10} display="flex" justifyContent="center" width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <Box display="flex" justifyContent="center">
                <Box position="relative">
                  <Imgix src={UploadImage} height="220px" />
                  <Box color="#4D4D4D" position="absolute" top={8} left={0}>
                    <Typography variant="subtitle2">上傳你的答案</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mx={5} display="flex" justifyContent="center">
                <Box color="#B70000" align="center">
                  <Typography variant="subtitle2">只適用於</Typography>
                  <Typography variant="subtitle2" noWrap>
                    測驗模式 和 考試模式
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box display="flex" justifyContent="center">
                <Box position="relative">
                  <Imgix src={MarkImage} height="220px" />
                  <Box color="#4D4D4D" position="absolute" top={8} right={0}>
                    <Typography variant="subtitle2">由專業閱卷員</Typography>
                    <Typography variant="subtitle2">批改及評分</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          bottom="-50px"
          id="concept"
        >
          {/* <AboutMethodText variant="h1">關於Mathod</AboutMethodText> */}
          <img src={AboutMethodSvg} height="80px" />
        </Box>
      </Container>
      <Box py={8} bgcolor="#F7F8F8">
        <Container>
          <Box color="#363636" display="flex" justifyContent="center">
            <Box width="75%">
              <Typography variant="subtitle2">
                Mathod成立於2018年，一直專注數學教育，致力研究適合每個學生的數學學習法。
              </Typography>
            </Box>
          </Box>
          <Box bgcolor="white" display="flex" my={2} maxHeight="50vh">
            <Box m={5} width="70%">
              <Box color="#2A80DB" pb={2}>
                <Typography variant="h4">傳統教學模式：</Typography>
              </Box>
              <Box color="#B8B8B8">
                <Typography variant="body1">
                  老師教授書本上的知識 > 老師給功課 > 學生按老師要求完成功課 >
                  測驗考試。
                  測驗考試是目前最有效評核學生學習進度的方法，這是毋容置疑。
                </Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              overflow="hidden"
            >
              <Imgix src={ClassroomImage} height="125%" />
            </Box>
          </Box>
          <Box bgcolor="white" display="flex" my={2} maxHeight="50vh">
            <Box m={5} width="70%">
              <Box color="#2A80DB" pb={2}>
                <Typography variant="h4">我們想突破的是</Typography>
              </Box>
              <Box color="#969696">
                <Typography variant="body1">
                  學習的主導權應該源自學生本身
                </Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-start"
              overflow="hidden"
            >
              <Imgix src={SpotlightImage} height="105%" />
            </Box>
          </Box>
          <Grid container spacing={2} justify="center" alignItems="stretch">
            <Grid item xs={4}>
              <Box
                display="flex"
                justifyContent="center"
                bgcolor="white"
                color="#2A80DB"
                borderRadius="49px"
                height="100%"
                py="16px"
                px="49px"
                fontSize="22px"
                fontWeight="700"
              >
                每個學生都有強弱項
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                justifyContent="center"
                bgcolor="white"
                color="#2A80DB"
                borderRadius="49px"
                py="16px"
                px="49px"
                fontSize="22px"
                fontWeight="700"
              >
                每一個學生都面對不同範圍的測驗考試
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                justifyContent="center"
                bgcolor="white"
                color="#2A80DB"
                borderRadius="49px"
                py="16px"
                px="49px"
                fontSize="22px"
                fontWeight="700"
              >
                每一個學生有突然想針對性的訓練課題
              </Box>
            </Grid>
          </Grid>
          <Box pt={2}>
            <Grid container spacing={0} justify="center" alignItems="stretch">
              <Grid item xs={12} lg={6}>
                <Box
                  bgcolor="#fff"
                  borderRadius="8px"
                  // maxHeight="100vh"
                  height="100%"
                >
                  <Box
                    color="#007bff"
                    display="flex"
                    justifyContent="center"
                    fontSize="35px"
                    fontWeight="700"
                    py={2}
                  >
                    只有學生自己知道自己需要甚麼
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="flex-end"
                    p={2}
                  >
                    <Imgix src={SittingBookImage} width="100%" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box height="100%" width="100%">
                  <Box height="100%" width="100%">
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      height="100%"
                      width="100%"
                      position="relative"
                      // overflow="hidden"
                      // maxHeight="100vh"
                    >
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-end"
                        // height="100%"
                      >
                        <Imgix src={FlyingBookImage} width="80%" />
                      </Box>
                      <Box
                        position="absolute"
                        color="#4d4d4d"
                        fontSize="35px"
                        fontWeight="700"
                        left="10%"
                        top="25%"
                      >
                        <Box>沒有一本練習</Box>
                        <Box>可以100%</Box>
                        <Box>滿足全部學生</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-end"
                  height="100%"
                  p={2}
                >
                  <Box width="60%" p={2}>
                    <Imgix src={BuildingHeadImage} width="100%" />
                  </Box>
                  <Box
                    width="40%"
                    height="100%"
                    fontSize="20px"
                    fontWeight="300"
                    color="#4D4D4D"
                    display="flex"
                    alignItems="center"
                    mx={2}
                  >
                    數學學習是一種思維訓練，每解一道數學題，都是一場自我對話。數學世界沒有灰色地帶，對與錯有清晰的界線劃分，大家面對的是一樣公平的規則。
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box bgcolor="#fff" borderRadius="8px" p={2}>
                  <Box
                    color="#007bff"
                    display="flex"
                    justifyContent="center"
                    fontSize="35px"
                    fontWeight="700"
                    height="20%"
                    py={2}
                    whiteSpace="nowrap"
                  >
                    每個學生都會找到自己的Method
                  </Box>
                  <Box width="100%" height="80%" display="flex">
                    <Box
                      width="60%"
                      p={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Imgix src={StrongShadowManImage} width="100%" />
                    </Box>
                    <Box
                      width="40%"
                      height="100%"
                      fontSize="20px"
                      fontWeight="300"
                      color="#4D4D4D"
                      display="flex"
                      alignItems="center"
                      p={2}
                    >
                      學習數學和學習樂器、健身、各種運動競技一樣，需要透過訓練一點一滴去進步，不同的是，數學訓練的對象是人的大腦，而訓練是不存在任何捷徑，沒有人可以一步登天。
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default IndexPage
